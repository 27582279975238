import React from "react";
import {
  Form,
  Button,
  Typography,
  Divider,
  Upload,
  message,
  Row,
  Col
} from "antd";
import { withRouter } from "react-router";
import Text from "antd/lib/typography/Text";
import { InboxOutlined } from "@ant-design/icons";
import "../clients.css";
const { Dragger } = Upload;

const { Title } = Typography;

const certificateOfIncorporationFileUploadProps = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const cancelledChequeFileUploadProps = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const otherDocumentsFileUploadProps = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const gstCertificateFileUploadProps = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  // const addressProofFileUploadProps = {
  //   name: "file",
  //   multiple: true,
  //   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  //   onChange(info) {
  //     const { status } = info.file;
  //     if (status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully.`);
  //     } else if (status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  //   onDrop(e) {
  //     console.log("Dropped files", e.dataTransfer.files);
  //   },
  // };

const KycDocuments = (props) => {
    const handleCancel = () => {
        props.history.push("/admin/clients/");
      };
  return (
    <>
      <div className="contentWrapper" style={{ height: "100%", flex: 1 }}>
          <Title level={5} className="cardTitle">
            KYC Documents
          </Title>
        <Divider />
        <Form
          layout="vertical"
          // onFinish={handleSubmit}
          className="settings_form"
        >
          <Row style={{lineHeight: 3}}>
            <Col span={12} className="uploadSection m2" style={{maxWidth: 'none !important'}}>
              <Text className="align-items-center d-flex flex-center">Certificate of Incorporation</Text>
              <Dragger {...certificateOfIncorporationFileUploadProps}>
                <p className="ant-upload-text">
                  <InboxOutlined />
                  Drag here to upload JPG/PNG
                  <br />
                  Or click here to attach
                </p>
              </Dragger>
            </Col>
            <Col span={12} className="uploadSection m2" style={{maxWidth: 'none !important'}}>
              <Text className="align-items-center d-flex flex-center">Cancelled Cheque</Text>
              <Dragger {...cancelledChequeFileUploadProps}>
                <p className="ant-upload-text">
                  <InboxOutlined />
                  Drag here to upload JPG/PNG
                  <br />
                  Or click here to attach
                </p>
              </Dragger>
            </Col>
            <Col span={12} className="uploadSection m2" style={{maxWidth: 'none !important'}}>
              <Text className="align-items-center d-flex flex-center">Other Documents</Text>
              <Dragger {...otherDocumentsFileUploadProps}>
                <p className="ant-upload-text">
                  <InboxOutlined />
                  Drag here to upload JPG/PNG
                  <br />
                  Or click here to attach
                </p>
              </Dragger>
            </Col>
            <Col span={12} className="uploadSection m2" style={{maxWidth: 'none !important'}}>
              <Text className="align-items-center d-flex flex-center">GST Certificate</Text>
              <Dragger {...gstCertificateFileUploadProps}>
                <p className="ant-upload-text">
                  <InboxOutlined />
                  Drag here to upload JPG/PNG
                  <br />
                  Or click here to attach
                </p>
              </Dragger>
            </Col>
            <Col span={12} className="uploadSection m2" style={{maxWidth: 'none !important'}}>
              <Text className="align-items-center d-flex flex-center">Address Proof</Text>
              <Dragger {...gstCertificateFileUploadProps}>
                <p className="ant-upload-text">
                  <InboxOutlined />
                  Drag here to upload JPG/PNG
                  <br />
                  Or click here to attach
                </p>
              </Dragger>
            </Col>
          </Row>
          <div className="d-flex mt3">
            <Button
              key="1"
              htmlType="submit"
              className="formButton mt1"
            >
              Save
            </Button>
            <Button
              key="2"
              className="cancelButton ml1 mt1"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default withRouter(KycDocuments);
