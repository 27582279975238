import React from "react";
import { Route } from "react-router-dom";
import UserDashboard from "../UserDashboard";
import EditUserDashboard from "../EditUser/EditUserDashboard";

const UserChildRoutes = (props) => {
  return (
    <div>
      <Route
        path={`${props.path}/edit-user`}
        render={({ match }) => {
          return <EditUserDashboard path={match.path} />;
        }}
      />
      <Route exact path={props.path} component={UserDashboard} />
    </div>
  );
};

export default UserChildRoutes;
