import * as React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Typography } from "antd";

const { Title } = Typography;
const renderNavItem = (navigation) => {
  const items = [];
  for (const key of Object.keys(navigation)) {
    items.push(
      navigation[key] ? (
        <Breadcrumb.Item key={key}>
          <Link key={key} to={navigation[key]}>
            {key}
          </Link>
        </Breadcrumb.Item>
      ) : (
        <span key={key}>{key}</span>
      )
    );
  }
  return items;
};

const PageHeader = (props) => {
  const { title, description } = props;
  return (
    <div className="pageHeader">
      <div>
        <p>
          {title}
        </p>
        <p>
          {description}
        </p>
      </div>
    </div>
  );
};

export default PageHeader;
