import React from "react";
import {
  Input,
  Form,
  Button,
  Typography,
  Divider,
  Upload,
  message,
  Row,
  Col,
} from "antd";
import { withRouter } from "react-router";
import Text from "antd/lib/typography/Text";
import { InboxOutlined } from "@ant-design/icons";
import "../users.css";
const { Dragger } = Upload;

const { Title } = Typography;
const addressProofPdfFile = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};
const KycDetails = (props) => {
  const handleCancel = () => {
    props.history.push("/admin/users/");
  };
  // function onChange(checked) {
  //   console.log(`switch to ${checked}`);
  // }

  return (
    <div className="contentWrapper" style={{ height: "100%" }}>
      <div className="d-flex flex-between">
        <Title level={5} className="cardTitle">
          KYC Details
        </Title>
      </div>
      <Divider />
      <div className="kycDetailsTitle mb1">Address Proof</div>
      <Form
        layout="vertical"
        // onFinish={handleSubmit}
        className="settings_form"
      >
        <Row>
          <Col span={6}>
            <Form.Item label="Document Type" name="document_type">
              <Input placeholder="Document Type" className="inputTag" />
            </Form.Item>
            <Form.Item label="Document Number" name="document_number">
              <Input placeholder="Document Number" className="inputTag" />
            </Form.Item>
          </Col>
          <Col span={6} className="uploadSection align-items-center">
            <Text>Address Proof</Text>
            <Dragger {...addressProofPdfFile}>
              <p className="ant-upload-text">
                <InboxOutlined />
                Drag here to upload JPG/PNG
                <br />
                Or click here to attach
              </p>
            </Dragger>
            <p className="hint">
              Preferred Image Size: 240px x 240px @ 72 DPI Maximum size of 1MB.
            </p>
          </Col>
        </Row>
        <div className="d-flex flex-col">
          <Text>Document Status</Text>
          <span className="kycpendingbutton mt1">Pending</span>
        </div>
        <div className="kycDetailsTitle mt2 mb1">PAN Card</div>
        <Row>
          <Col span={6}>
            <Form.Item label="Document Type" name="document_type">
              <Input placeholder="Document Type" className="inputTag" />
            </Form.Item>
            <Form.Item label="Document Number" name="document_number">
              <Input placeholder="Document Number" className="inputTag" />
            </Form.Item>
          </Col>
          <Col span={6} className="uploadSection align-items-center">
            <Text>PAN Card</Text>
            <Dragger {...addressProofPdfFile}>
              <p className="ant-upload-text">
                <InboxOutlined />
                Drag here to upload JPG/PNG
                <br />
                Or click here to attach
              </p>
            </Dragger>
            <p className="hint">
              Preferred Image Size: 240px x 240px @ 72 DPI Maximum size of 1MB.
            </p>
          </Col>
        </Row>
        <div className="d-flex flex-col mb2">
          <Text>Document Status</Text>
          <span className="kycunsubmittedbutton d-flex flex-center mt1">
            Un-Submitted
          </span>
        </div>

        <div className="d-flex">
          <Button key="1" htmlType="submit" className="formButton mt1 w10">
            Save
          </Button>
          <Button
            key="2"
            className="cancelButton ml1 mt1 w10"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default withRouter(KycDetails);
