import React from "react";
import { Input, Form, Button, Typography, Divider, Col } from "antd";
import { withRouter } from "react-router";
import "../clients.css";
const { Title } = Typography;

const RegionalSettings = (props) => {
  const handleCancel = () => {
    props.history.push("/admin/clients/");
  };

  return (
    <>
      <div className="contentWrapper" style={{ height: "100%", flex: 1 }}>
        <Title level={5} className="cardTitle">
          Regional Settings
        </Title>
        <Divider />
        <Form
          layout="vertical"
          // onFinish={handleSubmit}
          className="settings_form"
        >
          <Col span={12}>
            <Form.Item label="Base Currency" name="base_currency">
              <Input placeholder="Base Currency" className="inputTag" />
            </Form.Item>
            <Form.Item label="Time Zone" name="time_zone">
              <Input placeholder="Time Zone" className="inputTag" />
            </Form.Item>
            <Form.Item label="Fiscal Year" name="fiscal_year">
              <Input placeholder="Fiscal Year" className="inputTag" />
            </Form.Item>
          </Col>
          <div className="d-flex mt3">
            <Button key="1" htmlType="submit" className="formButton mt1">
              Save
            </Button>
            <Button
              key="2"
              className="cancelButton ml1 mt1"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default withRouter(RegionalSettings);
