import React from "react";
import { Route } from "react-router-dom";
import AddNewClientDashboard from "../AddNewClientDashboard/addNewClientDashboard.js";
// import AddNewClientOrganisationProfile from "../AddNewClientDashboard/OrganisationProfile";
import ClientDashboard from "../ClientDashboard";
import EditProfileDashboard from "../EditProfile/EditProfileDashboard.js";
// import EditProfileOrganisationProfile from "../EditProfile/OrganisationProfile";

const ClientChildRoutes = (props) => {
  return (
    <div>
      <Route
        path={`${props.path}/add-new-client`}
        render={({ match }) => {
          return <AddNewClientDashboard path={match.path} />;
        }}
      />
      <Route
        path={`${props.path}/edit-profile`}
        render={({ match }) => {
          return <EditProfileDashboard path={match.path} />;
        }}
      />
      <Route exact path={props.path} component={ClientDashboard} />
    </div>
  );
};

export default ClientChildRoutes;
