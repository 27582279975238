import React, { useEffect } from 'react';

const PasswordCheck = ({ password, dark, setPasswordCheck}) => {
  const alphabet = /[a-z]/
  const alphabetUpper = /[A-Z]/
  const specialcharacters = /[@$!^%#?&]/
  const number = /[0-9]/

  console.log(password,"passwordsss===>")

  useEffect(() => {
    if (password)  {
      setPasswordCheck(!alphabet.test(password) ||
        !specialcharacters.test(password) ||
        !alphabetUpper.test(password) ||
        !number.test(password))
    } else {
      setPasswordCheck(false)
    }
    // eslint-disable-next-line
  }, [password])
  return (
    <div style={{ color: "red", fontSize: 14 }}>
      {!alphabet.test(password) ||
        !specialcharacters.test(password) ||
        !alphabetUpper.test(password) ||
        !number.test(password) ? (
        password && <>
          Ensure your password contains <br />
          {!alphabet.test(password)}
          {!alphabet.test(password) ? (
            <>
              atleast one lowercase letter (a-z)
              <br />
            </>
          ) : (
            ''
          )}
          {!alphabetUpper.test(password)}
          {!alphabetUpper.test(password) ? (
            <>
              atleast one uppercase letter (A-Z)
              <br />
            </>
          ) : (
            ''
          )}
          {!number.test(password)}
          {!number.test(password) ? (
            <>
              atleast one number (0-9)
              <br />
            </>
          ) : (
            ''
          )}
          {!specialcharacters.test(password)}
          {!specialcharacters.test(password) ? (
            <>
              atleast special character ($@%!)
              <br />
            </>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default PasswordCheck;