import { ActionTypesFactory } from "../../utils";

export const Categories = ActionTypesFactory("Categories", "Categories");
export const CategoriesStatus = ActionTypesFactory(
  "CategoriesStatus",
  "Categories"
);
export const AddCategories = ActionTypesFactory("AddCategories", "Categories");
export const DeleteCategories = ActionTypesFactory(
  "DeleteCategories",
  "Categories"
);

// export const authorize = (payload) => {
//   console.log("step one");
//   return {
//     type: Authorize.REQUEST,
//     payload,
//   };
// };

export const categories = (payload) => {
  return {
    type: Categories.REQUEST,
    payload,
  };
};

export const categoriesStatus = (payload) => {
  return {
    type: CategoriesStatus.REQUEST,
    payload,
  };
};

export const addCategories = (payload) => {
  return {
    type: AddCategories.REQUEST,
    payload,
  };
};

export const deleteCategories = (payload) => {
  return {
    type: DeleteCategories.REQUEST,
    payload,
  };
};

// export const commits = (payload) => {
//   let str = "";
//   Object.keys(payload).forEach((item) => {
//     str = str + `${item}:${payload[item]} `;
//   });
//   payload = encodeURIComponent(str);
//   return {
//     type: Commits.REQUEST,
//     payload,
//   };
// };
