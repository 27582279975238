import React, { useEffect, useState } from "react";
import { Route, withRouter } from "react-router";
import { Typography } from "antd";
import ApprovalsIcon from "../../../assets/icons/Policies_Limits@3x.png";
import { CloseCircleOutlined } from "@ant-design/icons";
import PageHeader from "../../PageHeader";
import UserProfile from "./UserProfile";
import KycDetails from "./KycDetails";
import CardDetails from "./CardDetails";
import "../../Clients/clients.css";
const { Title } = Typography;

const leftSideButtons = [
  {
    buttonName: "User Profile",
    icon: ApprovalsIcon,
    path: "/user-profile",
  },
  {
    buttonName: "Card Details",
    icon: ApprovalsIcon,
    path: "/card-details",
  },
  {
    buttonName: "KYC Details",
    icon: ApprovalsIcon,
    path: "/kyc-details",
  },
  {
    buttonName: "Privacy & Security",
    icon: ApprovalsIcon,
    path: "/privacy-security",
  },
  {
    buttonName: "Sessions",
    icon: ApprovalsIcon,
    path: "/sessions",
  },
  {
    buttonName: "Settings",
    icon: ApprovalsIcon,
    path: "/settings",
  },
];

const EditUserDashboard = (props) => {
  const [updatePageIndex, setUpdatePageIndex] = useState(0);
  const history = props.history;

  useEffect(() => {
    history.push(`/users/edit-user/user-profile`);
  }, [history]);

  const navigateToPage = (index, route) => {
    setUpdatePageIndex(index);
    history.push(`/users/edit-user${route}`);
  };

  return (
    <>
      <div className="heading-div d-flex flex-between">
        <PageHeader
          title={`User Name`}
        />
        <div className="d-flex">
          <Title level={5} className="cardTitle mr1">
            PCUID - 2021000
          </Title>
          <CloseCircleOutlined
            style={{ fontSize: 20 }}
            onClick={() => history.push("/admin/users/")}
          />
        </div>
      </div>
      <div
        className="d-flex flex-start"
        style={{ alignItems: "flex-start", height: "95%" }}
      >
        <div className="addNewClientLeftSideCard">
          {leftSideButtons.map((leftSideButton, index) => (
            <div
              className={
                updatePageIndex === index
                  ? "activeLeftSideButton d-flex flex-between w100 p1"
                  : "inActiveLeftSideButton d-flex flex-between w100 p1"
              }
            >
              <button
                key={index}
                onClick={() => navigateToPage(index, leftSideButton.path)}
              >
                <img
                  className={
                    updatePageIndex === index
                      ? "activeLeftSideIconTag mr1"
                      : "inActiveLeftSideIconTag mr1"
                  }
                  src={leftSideButton.icon}
                  alt="leftside-icon"
                />
                {leftSideButton.buttonName}
              </button>
              {/* <span className="leftSideButtonCircle ml1"></span> */}
            </div>
          ))}
        </div>
        <div className="w100">
          <Route
            exact
            path={props.path + "/user-profile"}
            component={UserProfile}
          />
          <Route
            exact
            path={props.path + "/card-details"}
            component={CardDetails}
          />
          <Route
            exact
            path={props.path + "/kyc-details"}
            component={KycDetails}
          />
        </div>
      </div>
    </>
  );
};

export default withRouter(EditUserDashboard);
