import React from "react";
import { Form, Button, Typography, Divider } from "antd";
import { withRouter } from "react-router";
import CardDetailsList from "./CardDetailsList";
// import "../clients.css";
// const { Dragger } = Upload;

// const { TextArea } = Input;
const { Title } = Typography;

const CardDetails = (props) => {
  const handleCancel = () => {
    props.history.push("/admin/users/");
  };
  // function onChange(checked) {
  //   console.log(`switch to ${checked}`);
  // }

  return (
    <>
      <div
        className="contentWrapper"
        style={{ height: "100%", flex: 1, width: "80%" }}
      >
        <div className="d-flex flex-between">
          <Title level={5} className="cardTitle">
            Card Details
          </Title>
        </div>
        <Divider />
        <Form
          layout="vertical"
          // onFinish={handleSubmit}
          className="settings_form w100"
        >
          <CardDetailsList />
          <div className="d-flex">
            <Button key="1" htmlType="submit" className="formButton mt1 w10">
              Save
            </Button>
            <Button
              key="2"
              className="cancelButton ml1 mt1 w10"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default withRouter(CardDetails);
