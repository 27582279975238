import { DeleteOutlined, FormOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Modal, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddStaffUser, StaffUser, StaffUserDelete, addStaffUser, staffUserList, staffUserShow } from "../../../../actions/employees/employees";
import { hasAccess, objectToFormData } from "../../../../utils";
import Table from "../../../Common/Table/Table";
import PageHeader from "../../../PageHeader";
import AddStaff from "./AddStaff";
import { staffUserDelete } from "../../../../actions/employees";
import { headerTitles } from "../../../../actions/auth";

const Staff = () => {
  const [addStaffModal, setAddStaffModal] = useState();
  const [deleteModal, setDeleteModal] = useState();
  const [selectedData, setSelectedData] = useState({});
  const [currntPage, setCurrentPage] = useState("")
  const data = useSelector(state => state?.staff_users?.list?.data?.data);
  const dataLoading = useSelector(state => state?.staff_users?.loading);
  const deleteUser = useSelector(state => state?.staff_users?.delete);
  const user = useSelector(state => state?.staff_users?.emp?.data?.data?.[0]);
  const totalRecords = useSelector(
    (state) => state?.staff_users?.list?.data?.total_count
  );

  console.log(totalRecords, "RecordDetails====>")
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(staffUserList())

    dispatch(headerTitles({
      title: "Staff Users"
    }))
    dispatch({
      type: AddStaffUser.RESET
    })

    // eslint-disable-next-line
  }, [])


  useEffect(() => {
    if (deleteUser?.data?.status) {
      message.success("Staff user Deleted successfully")
      dispatch({
        type: StaffUserDelete.RESET
      })
      setDeleteModal(false)
      dispatch(staffUserList({ params: { page_number: currntPage.page_number } }))
    }
    if (deleteUser?.data?.status === false) {
      message.success("Failed to delete Staff user.")
      dispatch({
        type: StaffUserDelete.RESET
      })
    }
    // eslint-disable-next-line
  }, [deleteUser])


  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      //   render: text => <a href="/">{text}</a>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      //   render: text => <a href="/">{text}</a>,
    },
    {
      title: 'Phone No',
      dataIndex: 'phone_no',
      key: 'phone_no',
      //   render: text => <a href="/">{text}</a>,
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
      //   render: text => <a href="/">{text}</a>,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    hasAccess("staff_users", "write") ? {
      title: "Actions",
      dataIndex: "id",
      width: 100,
      key: "id",
      render: (text, record) => (record?.email !== localStorage.getItem("email") ?
        <Space>
          <FormOutlined className="cursor" onClick={() => {
            setAddStaffModal(true)
            dispatch(staffUserShow({ id: text }))
          }} />
          <DeleteOutlined className="cursor" onClick={() => {
            setDeleteModal(true)
            setSelectedData({ id: text })
          }} />
        </Space> : null
      ),
    } : {},
  ];

  return (
    <>
      <Table
        columns={columns}
        placeholder={"Search by Email, Name, Mobile No"}
        loading={dataLoading}
        data={data}
        pagination={{
          total: totalRecords || 0,
          onChange: (pagee) =>
            dispatch(staffUserList({ params: { page_number: pagee } }, setCurrentPage({ page_number: pagee }))),
        }}
        topFil={true}
        addBtn={hasAccess("staff_users", "write")}
        handleSearch={(val) => {
          if (val)
            dispatch(staffUserList({ search_key: val }))
          else
            dispatch(staffUserList())
        }}
        buttonText={"Add Staff User"}
        isHeaderButton={hasAccess("staff_users", "write")}
        handleButtonFunc={() => {
          setAddStaffModal(true)
        }} />
      <Modal
        visible={addStaffModal}
        title={`${user?.id ? 'Edit' : 'Add'} Staff User`}
        className="right-alinged-modal"
        footer={[]}
        onCancel={() => {
          setAddStaffModal(false)
          dispatch({ type: StaffUser.RESET })
        }}
      >
        {addStaffModal && <AddStaff selectedData={user} pageNumbers={currntPage} onCancel={() => {
          setAddStaffModal(false)
          dispatch({ type: StaffUser.RESET })
        }} />}
      </Modal>
      <Modal
        visible={deleteModal}
        title="Delete Staff User"
        footer={[]}
        closable={false}
      >
        Are you sure about deleting this staff user ?
        <div className='d-flex'>
          <Button key="1" htmlType="submit" className="formButton mt1" onClick={() => {
            dispatch(staffUserDelete(({
              id: selectedData.id
            })))
          }}>Delete</Button>
          <Button key="2" className="cancelButton ml1 mt1" onClick={() => {
            setDeleteModal(false)
            dispatch({
              type: StaffUser.RESET
            })
          }}>Cancel</Button>
        </div>
      </Modal>
    </>
  );
};

export default Staff;

