import { all } from "redux-saga/effects";
import { loginWatcher } from "./actions/auth/saga";
import { categoriesWatcher } from "./actions/categories/saga";
import { companyWatcher } from "./actions/clients/saga";
import { employeesWatcher } from "./actions/employees/saga";
import { cardsWatcher } from "./actions/cards/saga";

export function* rootSagas() {
  yield all([
    cardsWatcher(),
    loginWatcher(),
    categoriesWatcher(),
    employeesWatcher(),
    companyWatcher(),
  ]);
}
