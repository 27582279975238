import { ActionTypesFactory } from "../../utils";

export const Cards = ActionTypesFactory("Cards", "Cards");
export const CardImports = ActionTypesFactory("Cards", "CardImports");

export const CreateCardVendors = ActionTypesFactory("Cards", "CreateCardVendors");
export const GetCardVendors = ActionTypesFactory("Cards", "GetCardVendors");
export const LinkCardVendor = ActionTypesFactory("Cards", "LinkCardVendor");
export const UnlinkCardVendor = ActionTypesFactory("Cards", "UnlinkCardVendor");
export const DeleteCardVendor = ActionTypesFactory("Cards", "DeleteCardVendor");


export const get_card_vendors = (payload) => {
  return {
    type: GetCardVendors.REQUEST,
    payload,
  };
};

export const create_card_vendors = (payload) => {
  return {
    type: CreateCardVendors.REQUEST,
    payload,
  };
};

export const link_card_vendors = (payload) => {
  return {
    type: LinkCardVendor.REQUEST,
    payload,
  };
};

export const unlink_card_vendors = (payload) => {
  return {
    type: UnlinkCardVendor.REQUEST,
    payload,
  };
};

export const delete_card_vendors = (payload) => {
  return {
    type: DeleteCardVendor.REQUEST,
    payload,
  };
};


export const get_cards = (payload) => {
  return {
    type: Cards.REQUEST,
    payload,
  };
};


export const get_card_imports = (payload) => {
  return {
    type: CardImports.REQUEST,
    payload,
  };
};
