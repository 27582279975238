import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions } from "../../utils";
import { Categories, AddCategories, DeleteCategories, CategoriesStatus } from "./categories";

export function getCategories(req) {
  return sagaFunctions(Categories, "get", apis.categories, req.payload, {
    "Content-Type": "multipart/formdata",
    "Authorization": localStorage.getItem("token"),
  })();
}

export function updateCategoriesStatus(req) {
  return sagaFunctions(CategoriesStatus, "post", apis.categoriesStatus, req.payload, {
    "Content-Type": "multipart/formdata",
    "Authorization": localStorage.getItem("token"),
  })();
}

export function addCategories(req) {
  return sagaFunctions(AddCategories, "post", apis.categories, req.payload, {
    "Content-Type": "multipart/formdata",
    "Authorization": localStorage.getItem("token"),
  })();
}

export function deleteCategories(req) {
  return sagaFunctions(DeleteCategories, "post", apis.deleteCategories, req.payload, {
    "Content-Type": "multipart/formdata",
    "Authorization": localStorage.getItem("token"),
  })();
}

export function* categoriesWatcher() {
  yield takeLatest(Categories.REQUEST, getCategories);
  yield takeLatest(CategoriesStatus.REQUEST, updateCategoriesStatus);
  yield takeLatest(AddCategories.REQUEST, addCategories);
  yield takeLatest(DeleteCategories.REQUEST, deleteCategories);
}
