import { DatePicker } from 'antd';
import moment from 'moment';
import React from 'react';
import { Controller } from 'react-hook-form';

const DateInput = ({ value: val, errors, minDate, maxDate, isTime, errorMessage, label, onChange: onChangeOutside, placeholder, required, horizontal = false, disabled, dateFormat, picker, ...restOfProps }) => {
  return (
    <div>
      <Controller
        shouldUnregister={true}
        {...restOfProps}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <div>
            {/* <span style={{ fontSize: '14px', color: ((errors && errors[restOfProps.name]) || errorMessage) ? '#E0272B' : '#3B3B3B' }}> */}
            <span style={{ fontSize: '14px', color: '#3B3B3B' }}>
              {label}
              {required && <sup style={{
                color: "red",
                fontSize: "14px",
                position: "relative",
                top: "-1px"
              }}>*</sup>}
            </span>
            <div style={{ paddingTop: '5px' }}>
              <DatePicker
                name={name}
                className="pp-form-input"
                format={dateFormat ? [...dateFormat] : ['DD/MM/YYYY HH:mm:ss']}
                disabledDate={(current) => {
                  console.log("date input current value", { current: current, minDate: minDate, maxDate: maxDate })
                  if (minDate && maxDate)
                    return current && (current < moment(minDate, "DD/MM/YYYY") || current > moment(maxDate, "DD/MM/YYYY"))
                  else if (minDate)
                    return current && current < moment(minDate, "DD/MM/YYYY")
                  else if (maxDate)
                    return current && current > moment().endOf('day');
                }}
                onChange={onChange}
                value={val ? val : value}
                style={{ width: '100%' }}
                showTime={isTime}
                disabled={disabled}
                picker={picker ? picker : "date"}
              />
              {(errors?.[restOfProps?.name]?.message || errorMessage) ? <div className='errorMsg'>{errors?.[restOfProps?.name]?.message || errorMessage}</div> : null}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default DateInput;
