import { ReducerFunctions } from "../../utils";
import { CardImports, Cards, CreateCardVendors, DeleteCardVendor, GetCardVendors, LinkCardVendor, UnlinkCardVendor } from "./cards";

export const get_cards_reducer = ReducerFunctions(Cards, {});
export const get_card_imports_reducer = ReducerFunctions(CardImports, {});
export const get_card_vendors_reducer = ReducerFunctions(GetCardVendors, {});
export const create_card_vendors_reducer = ReducerFunctions(CreateCardVendors, {});
export const link_card_vendors_reducer = ReducerFunctions(LinkCardVendor, {});
export const unlink_card_vendors_reducer = ReducerFunctions(UnlinkCardVendor, {});
export const delete_card_vendors_reducer = ReducerFunctions(DeleteCardVendor, {});
