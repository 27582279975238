import React from "react";
import { Button, Typography, Divider, Checkbox } from "antd";
import { withRouter } from "react-router";
import { sections } from "./ProductConfigurationData.json";
import "../clients.css";
const { Title } = Typography;

const Traverse = (props) => {
  const data = props.sections;

  return (
    data &&
    data.map((section) => (
      <div className="childSectionMainDiavTag">
        <div className="subChildSectionMainDiavTag">
          <div className="expandLessCheckbox flex-center d-flex">-</div>
          <span className="labelTitle mr2">{section.name}</span> <Checkbox />
        </div>
        <div style={{ marginLeft: 30 }}>{Traverse(section)}</div>
      </div>
    ))
  );
};
const ProductConfiguration = (props) => {
  const handleCancel = () => {
    props.history.push("/admin/clients/");
  };

  return (
    <>
      <div className="contentWrapper" style={{ height: "100%", flex: 1 }}>
        <Title level={5} className="cardTitle">
          Product Configuration
        </Title>
        <Divider />
        <Traverse sections={sections} />
        <div className="d-flex mt3">
          <Button key="1" htmlType="submit" className="formButton mt1">
            Save
          </Button>
          <Button
            key="2"
            className="cancelButton ml1 mt1"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default withRouter(ProductConfiguration);
