import React from "react";
import {
  Input,
  Form,
  Button,
  Typography,
  Divider,
  Row,
  Col,
  Switch,
} from "antd";
import { withRouter } from "react-router";
// import Text from "antd/lib/typography/Text";
// import { InboxOutlined } from "@ant-design/icons";
// import "../clients.css";
// const { Dragger } = Upload;

const { TextArea } = Input;
const { Title } = Typography;

const UserProfile = (props) => {
  const handleCancel = () => {
    props.history.push("/admin/users/");
  };
  function onChange(checked) {
    console.log(`switch to ${checked}`);
  }

  return (
    <>
      <div className="contentWrapper" style={{ height: "100%", flex: 1 }}>
        <div className="d-flex flex-between">
          <Title level={5} className="cardTitle">
            User Profile
          </Title>
          <div className="d-flex">
            <Title level={5} className="cardTitle">
              Status
            </Title>
            &nbsp;&nbsp;
            <Switch defaultChecked onChange={onChange} />
          </div>
        </div>
        <Divider />
        <Form
          layout="vertical"
          // onFinish={handleSubmit}
          className="settings_form"
        >
          <Row>
            <Col span={6}>
              <Form.Item label="Name" name="name">
                <Input placeholder="Name" className="inputTag" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Client" name="client">
                <Input placeholder="Client" className="inputTag" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item label="Email" name="email">
                <Input placeholder="Email" className="inputTag" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="PAN Number" name="panNumber">
                <Input placeholder="PAN Number" className="inputTag" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Mobile" name="mobile">
                <Input placeholder="Mobile" className="inputTag" />
              </Form.Item>
              <Form.Item label="Address" name="description">
                <TextArea
                  // value={value}
                  // onChange={this.onChange}
                  placeholder="Enter Address"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  className="inputTag"
                />
              </Form.Item>
          <Row>
            <Col span={6}>
              <Form.Item label="City" name="parent_name">
                <Input placeholder="City" className="inputTag" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="State" name="parent_name">
                <Input placeholder="State" className="inputTag" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Country" name="parent_name">
                <Input placeholder="Country" className="inputTag" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Pincode" name="parent_name">
                <Input placeholder="Pincode" className="inputTag" />
              </Form.Item>
            </Col>
          </Row>
          <div className="d-flex">
            <Button
              key="1"
              htmlType="submit"
              className="formButton mt1 w10"
            >
              Save
            </Button>
            <Button
              key="2"
              className="cancelButton ml1 mt1 w10"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default withRouter(UserProfile);
